.certification{
    width: 175px;
    height: 200px;
    padding: 50px 10px 0px 10px;
    font-size: 13px;
    background-color: rgb(223, 217, 217);
}

.generator{
    width: 480px;
    height: 550px;
    margin: 200px;
    padding: 10px 10px 0px 10px;
    font-size: 13px;
    background-color: rgb(221, 216, 191);
    border-radius: 10px;
 
    /* display: flex;
   transform: translate(50% 50%); */
}
.refresh{
    position: relative;
    transform: translate(-40px, -0px);
}
.slider {
    -webkit-appearance: none;
    width: 410px;
    height: 10px;
    /* accent-color: red; */
    background: #d3d3d3;
    -webkit-transition: .2s;
    border-radius: 5px;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* appearance: none; */
    width: 15px;
    height: 15px;
    background: #04AA6D;
    border-radius: 10px;
    cursor: pointer;
    /* accent-color: red; */
  }
  
  .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #04AA6D;
    cursor: pointer;
  }
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: #ffffff;
    border-color: black;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ffffff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #04AA6D;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 6px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid rgb(255, 255, 255);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .section{
    width: 70px;
    height: 20px;
    border:2px solid black;
    display: flex;
    margin-left: 42%;
    justify-content: space-around;
  }

/* problem 5- the section of the certification shows following  */
.pro5-secall{
  width: 20vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 45vw;
  margin-top:40vh;
}

.pro5-butt{
  width: 60px;
  height: 30px;
  padding: auto;
  background-color: green;
  border: none;
}
.pro5-butt:hover{
  background-color: #04AA6D;
}
.pro5-butt:active{
  background-color: aquamarine;
  box-shadow: #04AA6D;
  transform: translateY(4px);
}
.pro5-label{
  display: flex;
  flex-direction: column;
  gap:5px;
}